import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useEventList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refDraftEventsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'name',
      label: 'event name',
      sortable: true,
    },
    {
      key: 'dateLabel',
      label: 'Date & Time label',
      // sortable: true,
    },
    {
      key: 'bookingPeriod',
      label: 'booking period',
      sortable: true,
    },
    {
      key: 'soldQuantity',
      label: 'total qty sold',
      sortable: true,
    },
    {
      key: 'packages',
      label: 'Packages',
      sortable: true,
    },
    {
      key: 'waitingList',
      label: 'waiting list',
      sortable: true,
    },
    {
      key: 'timeSlotBooking',
      label: 'Timeslots Booking',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalEvents = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const timeSlotBookingFilter = ref([])
  if (Array.isArray(route.query.timeSlotBooking)) {
    timeSlotBookingFilter.value = route.query.timeSlotBooking
  } else if (route.query.timeSlotBooking) {
    timeSlotBookingFilter.value = [route.query.timeSlotBooking]
  }
  const toDeletedID = ref(null)
  const type = ref('draft')
  const eventData = ref([])
  const waitingListFilter = ref(route.query.waitingList ?? '')
  const startDateFilter = ref(route.query.startDate || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refDraftEventsTable.value ? refDraftEventsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEvents.value,
    }
  })

  const refetchData = () => {
    refDraftEventsTable.value.refresh()
  }

  watch([searchQuery, statusFilter, timeSlotBookingFilter, waitingListFilter, startDateFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchEvents = (ctx, callback) => {
    store
      .dispatch('app-custom-draft-events/fetchEvents', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        timeSlotBooking: timeSlotBookingFilter.value,
        waitingList: waitingListFilter.value,
        startDate: startDateFilter.value,
        type: type.value,
      },
      { root: true })
      .then(response => {
        const { events, total } = response.data
        eventData.value = events
        callback(events)
        totalEvents.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              timeSlotBooking: timeSlotBookingFilter.value,
              waitingList: waitingListFilter.value,
              startDate: startDateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteEvent = () => {
    store
      .dispatch('app-custom-draft-events/deleteEvent', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveEventStatusVariant = status => {
    if (status === 'published') return 'success'
    if (status === 'unpublished') return 'warning'
    if (status === 'draft') return 'secondary'
    return 'primary'
  }

  return {
    fetchEvents,
    tableColumns,
    perPage,
    currentPage,
    totalEvents,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDraftEventsTable,

    resolveEventStatusVariant,
    refetchData,

    toDeletedID,
    deleteEvent,
    eventData,

    // Extra Filters
    statusFilter,
    timeSlotBookingFilter,
    waitingListFilter,
    startDateFilter,
  }
}
