<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Events
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            :to="{ name: 'appointment-events-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Event</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'published'"
            lazy
            @click="changeRoute('published')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Published <span class="rounded-number">{{ publishedCount }}</span></span>
            </template>
            <published-event
              class="mt-2 pt-75"
              @emitCountEvent="countEvents"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            :active="$route.params.type == 'unpublished'"
            lazy
            @click="changeRoute('unpublished')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Unpublished <span class="rounded-number">{{ unpublishedCount }}</span></span>
            </template>
            <unpublished-event
              class="mt-2 pt-75"
              @emitCountEvent="countEvents"
            />
          </b-tab>

          <!-- Tab: Social -->
          <b-tab
            :active="$route.params.type == 'draft'"
            lazy
            @click="changeRoute('draft')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Draft <span class="rounded-number">{{ draftCount }}</span></span>
            </template>
            <draft-event
              class="mt-2 pt-75"
              @emitCountEvent="countEvents"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-event'"
            lazy
            @click="changeRoute('all-event')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Events <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-event
              class="mt-2 pt-75"
              @emitCountEvent="countEvents"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllEvent from './all-events/AllEvent.vue'
import PublishedEvent from './published-events/PublishedEvent.vue'
import UnpublishedEvent from './unpublished-events/UnpublishedEvent.vue'
import DraftEvent from './draft-events/DraftEvent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllEvent,
    PublishedEvent,
    UnpublishedEvent,
    DraftEvent,
  },
  data() {
    return {
      allCount: 0,
      publishedCount: 0,
      unpublishedCount: 0,
      draftCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Published',
          param: 'published',
          count: 0,
        },
        {
          title: 'Unpublished',
          param: 'unpublished',
          count: 0,
        },
        {
          title: 'Draft',
          param: 'draft',
          count: 0,
        },
        {
          title: 'All Events',
          param: 'all-event',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countEvents()
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },
    countEvents() {
      this.$http.get('appointment/events/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.publishedCount = response.data.publishedCount
          this.unpublishedCount = response.data.unpublishedCount
          this.draftCount = response.data.draftCount

          this.pageTabs.forEach(element => {
            if (element.title === 'All Events') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Draft') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.draftCount
            } else if (element.title === 'Unpublished') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.unpublishedCount
            } else if (element.title === 'Published') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.publishedCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
