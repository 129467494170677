<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            v-if="isMobile()"
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <div class="bulk_selection d-flex">
              <div class="action-left pt-50">
                <b-form-checkbox
                  :checked="selectUnpublishedEventCheckbox"
                  :indeterminate="isSelectUnpublishedEventCheckboxIndeterminate"
                  @change="selectAllCheckboxUpdate"
                >
                  Select All
                </b-form-checkbox>
              </div>
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-1"
                right
                text="Bulk Actions"
                variant="primary"
                :disabled="!selectedEvents.length"
              >
                <b-dropdown-item @click="downloadBulkCSV">
                  Download CSV
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('update', 'Event')"
                  v-b-modal.modal-update-status-unpublished-event-bulk
                >
                  Update Status
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canViewThisAction('delete', 'Event')"
                  @click="deleteBulkEvents"
                >
                  Delete Event(s)
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <b-col
            v-else
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectUnpublishedEventCheckbox"
                :indeterminate="isSelectUnpublishedEventCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedEvents.length"
            >
              <b-dropdown-item @click="downloadBulkCSV">
                Download CSV
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('update', 'Event')"
                v-b-modal.modal-update-status-unpublished-event-bulk
              >
                Update Status
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canViewThisAction('delete', 'Event')"
                @click="deleteBulkEvents"
              >
                Delete Event(s)
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-all-event"
                ref="filter_dropdown_all_event"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <event-dropdown-filters
                  :status-filter.sync="statusFilter"
                  :start-date.sync="startDateFilter"
                  :waiting-list.sync="waitingListFilter"
                  :time-slot-booking.sync="timeSlotBookingFilter"
                  :status-options="statusOptions"
                  :time-slot-booking-options="timeSlotBookingOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="timeSlotBookingFilter.length || startDateFilter || waitingListFilter || statusFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-if="startDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="startDateFilter = ''"
                  >
                    {{ startDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-if="waitingListFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="waitingListFilter = null"
                  >
                    Waiting List <span class="text-capitalize">({{ waitingListFilter }})</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="timeSlotVal in timeSlotBookingFilter"
                  :key="timeSlotVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromTimeSlotBookingFilter(timeSlotVal)"
                  >
                    <span class="text-capitalize">{{ resolveTimeSlotBookingName(timeSlotVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refUnpublishedEventsTable"
        class="position-relative event_list min-height-300"
        :items="fetchEvents"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedEvents.includes(data.item._id)"
              @change="toggleSelectedEvents(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'Event')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="link"
                class="weight-600 align-middle detail-view-id"
                :to="{ name: 'appointment-events-show', params: { id: data.item._id } }"
              >
                {{ data.item.stringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(name)="data">
          <div
            class="nameBackgroundColor"
          >
            {{ data.item.name }}
          </div>
        </template>

        <template #cell(soldQuantity)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.soldQuantity }}</span>
          </div>
        </template>

        <template #cell(packages)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.packages.length }}</span>
          </div>
        </template>

        <template #cell(waitingList)="data">
          <div class="text-nowrap">
            <span class="text-capitalize">{{ data.item.waitingList === true ? 'Yes' : 'No' }}</span>
          </div>
        </template>

        <template #cell(timeSlotBooking)="data">
          <div class="text-nowrap">
            <span class="text-capitalize">{{ data.item.timeSlotBooking }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveEventStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('create', 'Booking') || canViewThisAction('create', 'Event') || canViewThisAction('show', 'Event') || canViewThisAction('update', 'Event') || canViewThisAction('delete', 'Event')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('create', 'Booking')"
              :to="{ name: 'appointment-bookings-create', query: { event: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Create Booking</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'event-detail-frontend', params: { slug: data.item.slug } }"
              target="_blank"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View on Portal</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Event')"
              :to="{ name: 'appointment-events-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Details</span>
            </b-dropdown-item>

            <hr class="dividerHR">

            <b-dropdown-item
              v-if="canViewThisAction('update', 'Event')"
              :to="{ name: 'appointment-events-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit Event</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('create', 'Event')"
              :to="{ name: 'appointment-events-create', query: { clone: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Clone Event</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'Event')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete Event</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'Event')"
              id="toggle-btn"
              v-b-modal.modal-update-status-unpublished-event
              @click="setEvent(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalEvents > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalEvents"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="modal-update-status-unpublished-event"
      ref="update-status-modal"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="unpublishedEventStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-update-status-unpublished-event-bulk"
      ref="update-status-modal-unpublished-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="unpublishedEventBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormCheckbox, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BButton, BFormTag,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useUnpublishedEventList from './useUnpublishedEventList'
import eventStoreModule from '../eventStoreModule'
import EventDropdownFilters from '../EventDropdownFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    EventDropdownFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      eventID: '',
      typeID: '',
      name: '',
      status: 'published',
      bulkStatus: 'published',
      statusValidation: false,
      statusError: 'Valid status is required',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Published', code: 'published' },
        { title: 'Unpublished', code: 'unpublished' },
        { title: 'Set As Draft', code: 'draft' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,
      timeSlotBookingOptions: [
        { text: 'Required', value: 'required' },
        { text: 'Optional', value: 'optional' },
        { text: 'N/A', value: 'not available' },
      ],

      required,
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_all_event.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_event.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },

    removeFromTimeSlotBookingFilter(item) {
      const index = this.timeSlotBookingFilter.indexOf(item)
      if (index > -1) {
        this.timeSlotBookingFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.startDateFilter = ''
      this.waitingListFilter = ''
      this.timeSlotBookingFilter = []
      this.statusFilter = []
    },
    resolveTimeSlotBookingName(val) {
      const slotVal = this.timeSlotBookingOptions.find(o => o.value === val)
      if (slotVal) {
        return slotVal.text || ''
      }
      return ''
    },
    setEvent(event) {
      this.eventID = event._id
      this.status = event.status
      this.typeID = event.stringID
      this.name = event.name
    },
    downloadBulkCSV() {
      const formData = new FormData()
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.selectedEvents.length; i++) {
        formData.append('events[]', this.selectedEvents[i])
      }

      this.$http.post('appointment/events/action/bulk/download/csv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.selectedEvents = []
          const anchor = document.createElement('a')
          anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(response.data)}`
          anchor.target = '_blank'
          anchor.download = 'events.csv'
          anchor.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    updateBulkStatus() {
      this.$refs.unpublishedEventBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedEvents.length; i++) {
            formData.append('events[]', this.selectedEvents[i])
          }

          this.$http.patch('appointment/events/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountEvent')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected events has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteBulkEvents() {
      this.$swal({
        title: 'Are you sure?',
        html: 'This will <strong>delete</strong> selected event(s). This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            const formData = new FormData()
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.selectedEvents.length; i++) {
              formData.append('events[]', this.selectedEvents[i])
            }

            this.$http.patch('appointment/events/action/bulk/delete', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                this.refetchData()
                this.$emit('emitCountEvent')
                this.confirmCustomerVal = ''
                this.selectedEvents = []
                // this.$nextTick(() => {
                //   this.$refs['update-status-modal'].toggle('#toggle-btn')
                // })
                this.$swal({
                  title: 'Events Deleted!',
                  html: 'Selected events has been deleted',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    submitForm() {
      this.$refs.unpublishedEventStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`appointment/events/${this.eventID}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountEvent')
              // this.$nextTick(() => {
              //   this.$refs['update-status-modal'].toggle('#toggle-btn')
              // })
              this.$swal({
                title: 'Status Updated!',
                html: `The status for <strong>${this.typeID} ‘${this.name}’</strong> has been updated`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Selecting Delete will <strong>permanently delete</strong> this item. This action cannot be undone.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, Delete!',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteEvent()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const EVENT_APP_STORE_MODULE_NAME = 'app-custom-unpublished-events'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Published', value: 'published' },
      { label: 'Unpublished', value: 'unpublished' },
      { label: 'Draft', value: 'draft' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      eventData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUnpublishedEventsTable,
      refetchData,

      toDeletedID,
      deleteEvent,

      // UI
      resolveEventStatusVariant,

      // Extra Filters
      statusFilter,
      timeSlotBookingFilter,
      waitingListFilter,
      startDateFilter,
    } = useUnpublishedEventList()

    const selectedEvents = ref([])
    const toggleSelectedEvents = eventId => {
      const eventIndex = selectedEvents.value.indexOf(eventId)

      if (eventIndex === -1) selectedEvents.value.push(eventId)
      else selectedEvents.value.splice(eventIndex, 1)
    }
    const selectUnpublishedEventCheckbox = computed(() => eventData.value.length && (eventData.value.length === selectedEvents.value.length))
    const isSelectUnpublishedEventCheckboxIndeterminate = computed(() => Boolean(selectedEvents.value.length) && eventData.value.length !== selectedEvents.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEvents.value = val ? eventData.value.map(book => book._id) : []
    }

    return {

      // Sidebar

      fetchEvents,
      tableColumns,
      perPage,
      currentPage,
      totalEvents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUnpublishedEventsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteEvent,
      eventData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveEventStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      timeSlotBookingFilter,
      waitingListFilter,
      startDateFilter,

      selectUnpublishedEventCheckbox,
      isSelectUnpublishedEventCheckboxIndeterminate,
      selectedEvents,
      toggleSelectedEvents,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
